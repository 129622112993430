import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import {
  Container,
  Header as SUIHeader,
  Grid,
  Button as SUIButton,
  Image,
} from "semantic-ui-react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import TextContainer from "../components/text-container"

const GridRow = styled(Grid.Row)`
  @media only screen and (min-width: 1200px) {
    padding: 4em !important;
  }
`

const GridColumn = styled(Grid.Column)`
  @media only screen and (min-width: 1200px) {
    margin: 0em 2em !important;
  }
`

const Header = styled(SUIHeader)`
  font-size: ${props =>
    (props.as === "h1" && "2.6em") ||
    (props.as === "h2" && "2.2em") ||
    (props.as === "h3" && "1.5em")};
`

const Button = styled.a`
  width: 100%;
  padding: 2em 0em !important;
  margin-top: 2em !important;
  text-transform: uppercase !important;
  background: #d7203d !important;
  color: white !important;
`

const IndexPage = () => (
  <Layout>
    <SEO title="About Us" keywords={[`host family english`, `hfe`]} />
    <Hero
      title="ABOUT US"
      img="https://res.cloudinary.com/mystay-international/image/upload/v1555552610/Host%20Family%20English/hosting-faq-20-1024x661-1024x661.jpg"
    />
    <Grid
      stackable
      style={{
        marginLeft: "0em",
        marginRight: "0em",
      }}
    >
      <TextContainer>
        <h2>Industry Experts</h2>
        <p>
          Host Family English Mentoring was formed by a specialist group of
          English Language Training and Homestay professionals with a combined
          experience of over sixty years.
        </p>
        <p>
          Seeing a need for better student support when studying away from home,
          HFE brought together experts in the field of mentoring language
          training and homestay to create a program that supports not only the
          student but also the homestay experience and the Host.
        </p>
        <p>
          Using easy to learn mentoring techniques homestay hosts enrich the
          student experience, assist learning and cultural assimilation, while
          at the same time enjoying a more rewarding experience themselves.
        </p>
        <h2>Our Experience</h2>
        <p>
          A passionate team of Mentoring and English Language Training
          professionals, working in partnership with one of the world’s leading
          Homestay experts – meet the people behind Host Family English…
        </p>
      </TextContainer>
      <Grid.Row columns="3" style={{ padding: "0 4em" }}>
        <Grid.Column textAlign="center">
          <Image
            src="https://res.cloudinary.com/mystay-international/image/upload/c_fill,h_175,w_175/v1555552403/Host%20Family%20English/Jo-Humphreys.jpg"
            circular
            size="small"
            verticalAlign="middle"
            fluid
          />
          <h3>Joanne Humphreys</h3>
          <p>
            Joanne is a senior education-training specialist in the English
            Language with over 20 years’ experience in education, training and
            development.
          </p>
          <a href="https://www.hostfamilyenglish.com/about-us/our-experience/">
            Learn More
          </a>
        </Grid.Column>
        <Grid.Column textAlign="center">
          <Image
            src="https://res.cloudinary.com/mystay-international/image/upload/c_fill,h_175,w_175/v1555552549/Host%20Family%20English/Rory-Photo.jpg"
            circular
            size="small"
            verticalAlign="middle"
            fluid
          />
          <h3>Rory Mooney</h3>
          <p>
            Rory is a serious education reformer through the development of
            leaders, managers, mentors and teachers.
          </p>
          <a href="https://www.hostfamilyenglish.com/about-us/our-experience/">
            Learn More
          </a>
        </Grid.Column>
        <Grid.Column textAlign="center">
          <Image
            src="https://res.cloudinary.com/mystay-international/image/upload/c_fill,h_175,w_175/v1555552936/Host%20Family%20English/IMG_98321.jpg"
            circular
            size="small"
            verticalAlign="middle"
            fluid
          />
          <h3>David Bycroft</h3>
          <p>
            David is the founder of both the Australian and American Homestay
            Networks and is a specialist in B2B partner business development.
            David has been recognized for his commitment to quality support for
            International Student services.
          </p>
          <a href="https://www.hostfamilyenglish.com/about-us/our-experience/">
            Learn More
          </a>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Layout>
)

export default IndexPage
